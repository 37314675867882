
// exporting modules to be included the UMD bundle

import focusable from './focusable';
import key from './key';
import visibleArea from './visible-area';
export default {
  focusable,
  key,
  visibleArea,
};
